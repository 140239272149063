import { Injectable, Inject, PLATFORM_ID, signal, effect } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  User,
  Store,
  Order,
  Price,
  Category,
  SelectedItem,
  Client,
  Item,
  Menu,
} from '@services/api.service';
import { FormGroup } from '@angular/forms';

/**
 * Represents an extended store with additional properties.
 *
 * @extends Store
 */
export interface StoreExtended extends Store {}
/**
 * Represents an extended user with additional properties.
 *
 * @extends User
 */
export interface UserExtended extends User {}
/**
 * Represents an extended order with additional properties.
 *
 * @extends Order
 *
 * @property {number} quantityItems - The quantity of items in the order.
 */
export interface OrderExtended extends Order {
  quantityItems?: number;
  orderSelectedItems?: PriceExtended[];
}
/**
 * Represents an extended price with additional properties.
 *
 * @extends Price
 */
export interface PriceExtended extends Price {}
/**
 * Represents an extended category with additional properties.
 *
 * @extends Category
 */
export interface CategoryExtended extends Category {}
/**
 * Represents an extended selected item with additional properties.
 *
 * @extends SelectedItem
 */
export interface SelectedItemExtended extends SelectedItem {}
/**
 * Represents an extended client with additional properties.
 *
 * @extends Client
 */
export interface ClientExtended extends Client {}
/**
 * Represents an extended item with additional properties.
 *
 * @extends Item
 *
 * @property {FormGroup} form - The form group associated with the item.
 * @property {number} quantity - The quantity of the item.
 * @property {PriceExtended[]} priceItemExt - An array of extended price details for the item.
 */
export interface ItemExtended extends Item {
  form: FormGroup;
  quantity: number;
  priceItemExt: PriceExtended[];
}
/**
 * Represents an extended menu with additional properties.
 *
 * @extends Menu
 *
 * @property {ItemExtended[]} itemMenuExtended - An array of extended item details for the menu.
 */
export interface MenuExtended extends Menu {
  itemMenuExtended: ItemExtended[];
}

/**
 * Servicio de almacenamiento.
 * @returns {StorageService} Servicio de almacenamiento.
 * @template StoreExtended - Tipo de datos de la tienda.
 * List of signals: isLogged, session, user, store, order, price, category, selectedItem, client, item, menu, btnOpenCart.
 * List of public methods: cleanAll.
 * List of private methods: updateLocalStorage, getFromLocalStorage.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _isBrowser: boolean;

  /**
   * Creates an instance of StorageService.
   *
   * @param platformId - The platform identifier used to determine if the code is running in a browser environment.
   *
   * This constructor checks if the code is running in a browser environment. If it is, it sets up an effect that updates
   * the local storage with various pieces of data such as user, store, order, price, category, selectedItem, client, item,
   * menu, and session.
   */
  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this._isBrowser = isPlatformBrowser(platformId);
    if (this._isBrowser) {
      effect(() => {
        this.updateLocalStorage('user', this.user());
        this.updateLocalStorage('store', this.store());
        this.updateLocalStorage('order', this.order());
        this.updateLocalStorage('price', this.price());
        this.updateLocalStorage('category', this.category());
        this.updateLocalStorage('selectedItem', this.selectedItem());
        this.updateLocalStorage('client', this.client());
        this.updateLocalStorage('item', this.item());
        this.updateLocalStorage('menu', this.menu());
        this.updateLocalStorage('session', this.session());
      });
    }
  }

  /**
   * Actualiza un valor en `localStorage`.
   *
   * @param key La clave para almacenar el valor.
   * @param value El valor a almacenar.
   * @example this.updateLocalStorage('user', this.user());
   */
  private updateLocalStorage(key: string, value: any): void {
    try {
      if (this._isBrowser) localStorage.setItem(key, JSON.stringify(value));
      else console.error('updateLocalStorage: Not in browser');
    } catch (e) {
      console.error(`With support in: =>  Error saving ${key} to localStorage`, e);
    }
  }

  /**
   * Obtiene un valor de `localStorage`.
   *
   * @param key La clave del valor a obtener.
   * @param defaultValue El valor por defecto si no se encuentra la clave.
   * @returns El valor almacenado o el valor por defecto.
   * @example this.getFromLocalStorage('user', {} as UserExtended);
   */
  private getFromLocalStorage<T>(key: string, defaultValue: T): T {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : {};
    } catch (e) {
      console.error(`With support in: => Error loading ${key} from localStorage`, e);
    }
    return {} as T;
  }

  /**
   * Limpia todos los datos de `localStorage` y restablece el estado de la aplicación.
   *
   * @example
   * this.storageService.cleanAll();
   */
  cleanAll() {
    localStorage.clear();
    this.isLogged.set(false);
    this.session.set({ access: '', refresh: '' });
    this.user.set({} as UserExtended);
  }

  /**
   * Signal que indica si el usuario está autenticado.
   *
   * @example
   * this.storageService.isLogged.subscribe(isLoggedIn => {
   *   console.log('Usuario autenticado:', isLoggedIn);
   * });
   */
  isLogged = signal(
    this.getFromLocalStorage('session', {
      access: '',
      refresh: '',
    }).access !== '' &&
      this.getFromLocalStorage('session', {
        access: '',
        refresh: '',
      }).refresh !== '' &&
      this.getFromLocalStorage('user', {} as UserExtended).email !== '',
  );

  /**
   * Signal que almacena la sesión actual del usuario.
   *
   * @example
   * this.storageService.session.subscribe(session => {
   *   console.log('Sesión actual:', session);
   * });
   */
  session = signal(
    this.getFromLocalStorage('session', {
      access: '',
      refresh: '',
    }),
  );

  /**
   * Signal que almacena la información del usuario.
   *
   * @example
   * this.storageService.user.subscribe(user => {
   *   console.log('Usuario:', user);
   * });
   */
  user = signal(this.getFromLocalStorage('user', {} as UserExtended));

  /**
   * Signal que almacena la información de la tienda.
   *
   * @example
   * this.storageService.store.subscribe(store => {
   *   console.log('Tienda:', store);
   * });
   */
  store = signal(this.getFromLocalStorage('store', {} as StoreExtended));

  /**
   * Signal que almacena la información del pedido.
   *
   * @example
   * this.storageService.order.subscribe(order => {
   *   console.log('Pedido:', order);
   * });
   */
  order = signal(this.getFromLocalStorage('order', {} as OrderExtended));

  /**
   * Signal que almacena la información de precios.
   *
   * @example
   * this.storageService.price.subscribe(price => {
   *   console.log('Precio:', price);
   * });
   */
  price = signal(this.getFromLocalStorage('price', {} as PriceExtended));

  /**
   * Signal que almacena la información de categorías.
   *
   * @example
   * this.storageService.category.subscribe(category => {
   *   console.log('Categoría:', category);
   * });
   */
  category = signal(this.getFromLocalStorage('category', {} as CategoryExtended));

  /**
   * Signal que almacena la información de los elementos seleccionados.
   *
   * @example
   * this.storageService.selectedItem.subscribe(selectedItem => {
   *   console.log('Elemento seleccionado:', selectedItem);
   * });
   */
  selectedItem = signal(this.getFromLocalStorage('selectedItem', {} as SelectedItemExtended));

  /**
   * Signal que almacena la información del cliente.
   *
   * @example
   * this.storageService.client.subscribe(client => {
   *   console.log('Cliente:', client);
   * });
   */
  client = signal(this.getFromLocalStorage('client', {} as ClientExtended));

  /**
   * Signal que almacena la información del ítem.
   *
   * @example
   * this.storageService.item.subscribe(item => {
   *   console.log('Ítem:', item);
   * });
   */
  item = signal(this.getFromLocalStorage('item', {} as ItemExtended));

  /**
   * Signal que almacena la información del menú.
   *
   * @example
   * this.storageService.menu.subscribe(menu => {
   *   console.log('Menú:', menu);
   * });
   */
  menu = signal(this.getFromLocalStorage('menu', {} as MenuExtended));

  /**
   * Signal que indica si el carrito está abierto.
   *
   * @example
   * this.storageService.btnOpenCart.subscribe(isOpen => {
   *   console.log('Carrito abierto:', isOpen);
   * });
   */
  btnOpenCart = signal(false);
}
